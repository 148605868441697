import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import { Publication } from '../../types';
import { screenWidthSettings } from '../../constants';
import Layout from '../Layout';
import SEO from '../SEO';
import TableLink from './TableLink';

const isLink = (property: string) => {
  if (property.toLowerCase() === 'doi' || property.toLowerCase() === 'url') {
    return true;
  } else {
    return false;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: 'calc(100vh - 75px)',
      paddingBottom: '8px',
    },
    table: {
      width: '90%',
      [theme.breakpoints.down(screenWidthSettings.hideNavButtons)]: {
        width: '100%',
      },
      maxWidth: screenWidthSettings.laptopScreenSize,
      backgroundColor: 'white',
      boxShadow: '0 1px 5px 2px rgba(38,59,94,0.3)',
    },
    tableCell: {
      overflowX: 'hidden',
    },
  })
);

interface Props {
  pageContext: {
    publication: Publication;
  };
}

const PublicationIndividual = ({ pageContext: { publication } }: Props) => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title={publication.Title} description={publication.Citation} />
      <div className={classes.container}>
        <Table className={classes.table} aria-label='customized table'>
          <TableBody>
            {publication['Order of Properties'].map(
              (property: string, index: number) => (
                <TableRow key={index}>
                  <TableCell component='th' scope='row'>
                    {property}
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {isLink(property) ? (
                      <TableLink
                        property={property}
                        displayText={publication[property] as string}
                      />
                    ) : (
                      publication[property]
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </div>
    </Layout>
  );
};

export default PublicationIndividual;
