import React from 'react';

import { useMediaQuery } from '@material-ui/core';
import ExternalLink from '@material-ui/icons/Launch';

import { screenWidthSettings } from '../../constants';

const TableLink = ({
  property,
  displayText,
}: {
  property: string;
  displayText: string;
}) => {
  const isTabletScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.tabletScreenSize}px)`
  );
  let linkText: string = displayText;
  if (property === 'DOI') {
    linkText = 'http://dx.doi.org/' + displayText;
  }

  if (!isTabletScreen) {
    return (
      <a href={linkText} target='_blank' rel='noreferrer'>
        {displayText}
      </a>
    );
  }
  return (
    <a href={linkText} target='_blank' rel='noreferrer'>
      <ExternalLink />
    </a>
  );
};

export default TableLink;
